import {Routes} from '@angular/router';
import {loggedIn, loggedOut} from "./features/auth/infrastructure/guards/session-guards";
import {pageAccess} from "./features/auth/infrastructure/guards/page-access-guard";
import {PlatformSelectorComponent} from "./features/platform-selector/platform-selector.component";
import {hasPlatform} from "./features/auth/infrastructure/guards/has-platform-guiard";
import {LegalNoticeComponent} from './features/policies/legal-notice/legal-notice.component';
import {CookiesPolicyComponent} from './features/policies/cookies-policy/cookies-policy.component';
import {PrivacyPolicyComponent} from './features/policies/privacy-policy/privacy-policy.component';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/infrastructure/pages/auth-pages-routing.module'),
    canActivate: [loggedOut]
  },
  {
    path: 'users',
    loadChildren: () => import('./features/users/infrastructure/pages/user-pages-routing.module'),
    canActivate: [loggedIn, pageAccess('users'), hasPlatform]
  },
  {
    path: 'platforms',
    loadChildren: () => import('./features/palataforms/palataforms-routing.module'),
    canActivate: [loggedIn, pageAccess('palataforms'), hasPlatform]
  },
  {
    path: 'platform-selector',
    component: PlatformSelectorComponent,
    canActivate: [loggedIn, pageAccess('palataforms')]
  },
  {
    path: 'projects',
    loadChildren: () => import('./features/projects/projects-routing.module'),
    canActivate: [loggedIn, pageAccess('projects'), hasPlatform]
  },
  {
    path: 'profile',
    loadChildren: () => import('./features/profile/profile-routing.module'),
    canActivate: [loggedIn, pageAccess('profile'), hasPlatform]
  },
  {
    path: 'activities',
    loadChildren: () => import('./features/activities/activities-routing.module'),
    canActivate: [loggedIn, pageAccess('activities'), hasPlatform]
  },
  {
    path: 'groups',
    loadChildren: () => import('./features/groups/groups-routing.module'),
    canActivate: [loggedIn, pageAccess('groups'), hasPlatform]
  },
  {
    path: 'buy',
    loadChildren: () => import('./features/buy/buy-routing.module'),
    canActivate: [loggedIn, pageAccess('licences'), hasPlatform]
  },
  {
    path: 'projects-licences',
    loadChildren: () => import('./features/projects-licences/projects-licences-routing.module'),
    canActivate: [loggedIn, pageAccess('projectsLicences'), hasPlatform]
  },
  {
    path: 'activities-licences',
    loadChildren: () => import('./features/activities-licences/activities-licences-routing.module'),
    canActivate: [loggedIn, pageAccess('activitiesLicences'), hasPlatform]
  },
  {
    path: 'licences',
    loadChildren: () => import('./features/licences/licences-routing.module'),
    canActivate: [loggedIn, pageAccess('stripe'), hasPlatform]
    // canActivate: [loggedIn, pageAccess('licences')]
  },
  {
    path: 'my-licences',
    loadChildren: () => import('./features/my-licences/my-licences-routing.module'),
    canActivate: [loggedIn, pageAccess('licences'), hasPlatform]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./features/dashboard/dashboard-routing.module'),
    canActivate: [loggedIn, pageAccess('dashboard'), hasPlatform]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'cookies-policy',
    component: CookiesPolicyComponent
  },
  {
    path: 'legal-notice',
    component: LegalNoticeComponent
  },
  {
    path: 'invites',
    loadChildren: () => import('./features/invites/invites-routing.module'),
    // canActivate: [loggedIn, pageAccess('licences'), hasPlatform]
  },
  /*{
    path: 'permissions',
    loadChildren: () => import('./features/permissions/infrastructure/pages/permissions-pages-routing.module')
  },
  {
    path: 'reports',
    loadChildren: () => import('./features/reports/infrastructure/pages/reports-pages-routing.module'),
  },
  {
    path: 'groups-projects',
    loadChildren: () => import('./features/groups-projects/groups-projects-routing.module')
  },
  {
    path: 'groups-activities',
    loadChildren: () => import('./features/groups-activities/groups-activities-routing.module')
  },
  {
    path: 'users-plataforms',
    loadChildren: () => import('./features/users-plataforms/users-plataforms-routing.module')
  },
  {
    path: 'users-projects',
    loadChildren: () => import('./features/users-projects/users-projects-routing.module')
  },
  {
    path: 'users-activities',
    loadChildren: () => import('./features/users-activities/users-activities-routing.module')
  },
  {
    path: 'users-groups',
    loadChildren: () => import('./features/users-groups/users-groups-routing.module')
  },
  {
    path: 'users-projects-licences',
    loadChildren: () => import('./features/users-projects-licences/users-projects-licences-routing.module')
  },
  {
    path: 'users-activities-licences',
    loadChildren: () => import('./features/users-activities-licences/users-activities-licences-routing.module')
  },*/
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "activities",
  }
];
