import {Component, inject} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {PlatformThemeService} from './shared/infrastructure/services/platform-theme.service';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {CookiesModalComponent} from './features/policies/cookies-modal/cookies-modal.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private modalService = inject(NgbModal);
  title = 'REM Plataforma Educativa';

  constructor(
    config: NgbModalConfig,
    private platformThemeService: PlatformThemeService,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    let cookiesAccepted = localStorage.getItem('cookies-accepted');
    if (cookiesAccepted != 'true')
      this.modalService.open(CookiesModalComponent);
  }
}
