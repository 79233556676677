<main class="d-flex flex-column min-vh-100">

  <app-navbar />
  <ng-container>
    <div class="container py-5 flex-grow-1 animate__animated animate__fadeIn">

<h1>Política de privacidad</h1>
<p> </p>
<p>En esta política de privacidad establece la forma en que se van a gestionar los datos personales en esta web. Es imprescindible que la leas y aceptes antes de seguir navegando.</p>
<p> </p>
<p>remexperience.com es un proyecto de Agrup Lab SL.</p>
<p> </p>
<p>Entre los valores con los que nos identificamos está la transparencia, por eso deseamos que conozcas desde el inicio, que la privacidad de tus datos muy es importante para nosotros. En esta declaración de privacidad se explica qué datos personales recopilamos de nuestros usuarios y cómo los utilizamos. Te invitamos a leer detenidamente estos términos antes de facilitar tus datos personales en esta web.</p>
<p> </p>
<p>Nunca solicitamos información personal a menos que realmente sea necesaria para prestarte los servicios que nos requieras.</p>
<p> </p>
<p>Nunca compartimos información personal de nuestros usuarios con nadie, excepto para cumplir con la ley o en caso que contemos con tu autorización expresa.</p>
<p> </p>
<p>Nunca utilizaremos tus datos personales con una finalidad diferente a la expresada en esta política de privacidad.</p>
<p> </p>
<p>Tenemos que advertirte que esta Política de Privacidad podría variar en función de exigencias legislativas o de autorregulación, por lo que se aconseja a los usuarios que la visiten periódicamente y que será aplicable en caso de que los usuarios decidan rellenar cualquiera de sus formularios de contacto donde se recaben datos de carácter personal.</p>
<p> </p>
<p>REM Experience ha adecuado esta web a las exigencias de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), y al Real Decreto 1720/2007, de 21 de diciembre, conocido como el Reglamento de desarrollo de la LOPD. Cumple también con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas (RGPD), así como con la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE o LSSI).</p>
<p> </p>
<p> </p>
<p> </p>
<h4>Responsable del tratamiento de tus datos personales</h4>
<p> </p>
<p><strong>Identidad del Responsable:</strong> Agrup Lab SL<br /><strong>Nombre comercial:</strong> REM Experience<br /><strong>CIF:</strong> B02877876<br /><strong>Dirección postal:</strong> C/ciutadella 73 2-2 Mao 07701 Baleares<br /><strong>Correo electrónico:</strong> <a href="mailto:info@remexperience.com">info&#64;remexperience.com</a><br /><strong>Actividad:</strong> Desarrollo de aplicaciones y videojuegos culturales y educativos.</p>
<p> </p>
<p>A efectos de lo previsto en el Reglamento General de Protección de Datos antes citado, los datos personales que nos envíes a través de los formularios de la web, recibirán el tratamiento de datos de “Usuarios de la web y suscriptores” y son tratados con el fin de responder a las solicitudes que realices en la web  y enviarte información sobre los servicios que nos consultes.</p>
<p> </p>
<p>Para el tratamiento de los datos de nuestros usuarios, implementamos todas las medidas técnicas y organizativas de seguridad establecidas en la legislación vigente.</p>
<p> </p>
<h4>Legitimación para el tratamiento de tus datos</h4>
<p> </p>
<p>La base legal para el tratamiento de sus datos es: el consentimiento.</p>
<p> </p>
<h4>¿Cuáles son tus derechos cuando nos facilitas tus datos?</h4>
<p> </p>
<p>Cualquier persona tiene derecho a obtener confirmación sobre si en REM Experience estamos tratando datos personales que le concierne, o no.</p>
<p> </p>
<p>Las personas interesadas tienen derecho a:</p>
<p> </p>
<ul>
<li>Solicitar el acceso a los datos personales relativos al interesado</li>
<li>Solicitar su rectificación o supresión</li>
<li>Solicitar la limitación de su tratamiento</li>
<li>Oponerse al tratamiento</li>
<li>Solicitar la portabilidad de los datos</li>
</ul>
<p> </p>
<p>Los interesados podrán acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.</p>
<p> </p>
<p>En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos.</p>
<p> </p>
<p>REM Experience dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones. Cómo interesado, tienes derecho a recibir los datos personales que te incumban, que nos hayas facilitado y en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro responsable del tratamiento cuando:</p>
<p> </p>
<ul>
<li>El tratamiento esté basado en el consentimiento</li>
<li>Los datos hayan sido facilitados por la persona interesada.</li>
<li>El tratamiento se efectúe por medios automatizados.</li>
</ul>
<p> </p>
<p>Al ejercer tu derecho a la portabilidad de los datos, tendrás derecho a que los datos personales se transmitan directamente de responsable a responsable cuando sea técnicamente posible.<br />Los interesados también tendrán derecho a la tutela judicial efectiva y a presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si consideran que el tratamiento de datos personales que le conciernen infringe el Reglamento.</p>
<p> </p>
<h4>¿Por cuánto tiempo conservaremos tus datos?</h4>
<p> </p>
<p>Los datos personales proporcionados se conservarán:</p>
<p> </p>
<ul>
<li>Mientras se mantenga la relación mercantil.</li>
<li>Hasta que no se solicite su supresión por el interesado.</li>
</ul>
<p> </p>
<h4>¿A qué destinatarios se comunicarán tus datos?</h4>
<p> </p>
<p>Muchas herramientas que utilizamos para gestionar tus datos son contratadas por terceros.</p>
<p> </p>
<p>Para prestar servicios estrictamente necesarios para el desarrollo de la actividad, remexperience.com, comparte datos con los siguientes prestadores bajo sus correspondientes condiciones de privacidad:</p>
<p> </p>
<p><strong>Google Analytics</strong>: un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Google Analytics utiliza “cookies”, que son archivos de texto ubicados en tu ordenador, para ayudar a yoseomarketing.com a analizar el uso que hacen los usuarios del sitio web. La información que genera la cookie acerca de su uso de yoseomarketing.com (incluyendo tu dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.</p>
<p> </p>
<p><strong>Hosting</strong>: SiteGround Spain S.L. es una empresa constituida conforme al Derecho Español, se encuentra debidamente inscrita en el Registro Mercantil de Madrid, al tomo 33.085, folio 1, Hoja número M- 595464, inscripción 1ª, con CIF : B87194171, y con domicilio social: Calle de Prim 19, 28004 Madrid, España. SiteGround Spain S.L. trata los datos con la finalidad de realizar sus servicios de hosting web y de correo a REM Experience.</p>
<p> </p>
<h4>Contenido incrustado de otros sitios web</h4>
<p> </p>
<p>Los artículos de este sitio pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de otras web se comporta exactamente de la misma manera que si el visitante hubiera visitado la otra web.</p>
<p> </p>
<p>Estas web pueden recopilar datos sobre ti, utilizar cookies, incrustar un seguimiento adicional de terceros, y supervisar tu interacción con ese contenido incrustado, incluido el seguimiento de tu interacción con el contenido incrustado si tienes una cuenta y estás conectado a esa web.</p>
<p> </p>
<p><strong>Youtube</strong></p>
<p> </p>
<p>Nuestro sitio web utiliza complementos de YouTube, que es operado por Google. El operador de las páginas es YouTube LLC, 901 Cherry Ave., San Bruno, CA 94066, EE. UU.</p>
<p> </p>
<p>Si visita una de nuestras páginas con un complemento de YouTube, se establece una conexión con los servidores de YouTube. Aquí el servidor de YouTube está informado sobre cuál de nuestras páginas ha visitado.</p>
<p> </p>
<p>Si ha iniciado sesión en su cuenta de YouTube, YouTube le permite asociar su comportamiento de navegación directamente con su perfil personal. Puede evitar esto cerrando la sesión de su cuenta de YouTube.</p>
<p> </p>
<p>YouTube se utiliza para ayudar a que nuestro sitio web sea atractivo. Esto constituye un interés justificado de conformidad con el art. 6 (1) (f) DSGVO.</p>
<p> </p>
<p>Se puede encontrar más información sobre el manejo de datos de usuario en la declaración de protección de datos de YouTube en https://www.google.de/intl/de/policies/privacy.</p>
<p> </p>
<p><strong>Vimeo</strong></p>
<p> </p>
<p>Nuestro sitio web utiliza las características proporcionadas por el portal de video Vimeo. Este servicio es provisto por Vimeo Inc., 555 West 18th Street, Nueva York, Nueva York 10011, EE. UU.</p>
<p> </p>
<p>Si visita una de nuestras páginas con un complemento de Vimeo, se establece una conexión con los servidores de Vimeo. Aquí el servidor de Vimeo está informado sobre cuáles de nuestras páginas ha visitado. Además, Vimeo recibirá su dirección IP. Esto también se aplica si no ha iniciado sesión en Vimeo cuando visita nuestro complemento o no tiene una cuenta de Vimeo. La información se transmite a un servidor Vimeo en los EE. UU., Donde se almacena.</p>
<p> </p>
<p>Si ha iniciado sesión en su cuenta de Vimeo, Vimeo le permite asociar su comportamiento de navegación directamente con su perfil personal. Puede evitar esto cerrando la sesión de su cuenta de Vimeo.</p>
<p> </p>
<p>Para obtener más información sobre cómo manejar los datos del usuario, consulte la Política de privacidad de Vimeo en https://vimeo.com/privacy.</p>
<p> </p>
<p><strong>Google Web Fonts</strong></p>
<p> </p>
<p>Para una representación uniforme de las fuentes, esta página utiliza fuentes web proporcionadas por Google. Cuando abre una página, su navegador carga las fuentes web requeridas en la caché de su navegador para mostrar correctamente los textos y las fuentes.</p>
<p> </p>
<p>Para este fin, su navegador debe establecer una conexión directa con los servidores de Google. Por lo tanto, Google se da cuenta de que nuestra página web se accedió a través de su dirección IP. El uso de Google Web Fonts se realiza con el interés de una presentación uniforme y atractiva de nuestro plugin. Esto constituye un interés justificado de conformidad con el art. 6 (1) (f) DSGVO.</p>
<p> </p>
<p>Si su navegador no admite fuentes web, su computadora utiliza una fuente estándar.</p>
<p> </p>
<p>Se puede encontrar más información sobre el manejo de los datos del usuario en https://developers.google.com/fonts/faq y en la política de privacidad de Google en https://www.google.com/policies/privacy/.</p>
<p> </p>
<h4>Navegación</h4>
<p> </p>
<p>Al navegar por remexperience.com se pueden recoger datos no identificables, que pueden incluir, direcciones IP, ubicación geográfica (aproximadamente), un registro de cómo se utilizan los servicios y sitios, y otros datos que no pueden ser utilizados para identificar al usuario. Entre los datos no identificativos están también los relacionados a tus hábitos de navegación a través de servicios de terceros. Esta web utiliza los siguientes servicios de análisis de terceros:</p>
<p> </p>
<p>Google analytics. Utilizamos esta información para analizar tendencias, administrar el sitio, rastrear los movimientos de los usuarios alrededor del sitio y para recopilar información demográfica sobre nuestra base de usuarios en su conjunto.</p>
<p> </p>
<h4>Secreto y seguridad de los datos</h4>
<p> </p>
<p>remexperience.com se compromete en el uso y tratamiento de los datos incluidos personales de los usuarios, respetando su confidencialidad y a utilizarlos de acuerdo con la finalidad del mismo, así como a dar cumplimiento a su obligación de guardarlos y adaptar todas las medidas para evitar la alteración, pérdida, tratamiento o acceso no autorizado, de conformidad con lo establecido en la normativa vigente de protección de datos.</p>
<p> </p>
<p>Esta web incluye un certificado SSL. Se trata de un protocolo de seguridad que hace que tus datos viajen de manera íntegra y segura, es decir, la transmisión de los datos entre un servidor y usuario web, y en retroalimentación, es totalmente cifrada o encriptada.</p>
<p> </p>
<p>remexperience.com no puede garantizar la absoluta inexpugnabilidad de Internet y por tanto la violación de los datos mediante accesos fraudulentos a ellos por parte de terceros.</p>
<p> </p>
<p>Con respecto a la confidencialidad del procesamiento se asegurará de que cualquier persona que esté autorizada por REM Experience para procesar los datos del cliente (incluido su personal, colaboradores y prestadores), estará bajo la obligación apropiada de confidencialidad (ya sea un deber contractual o legal).</p>
<p> </p>
<p>Cuando se presente algún incidente de seguridad, al darse cuenta REM Experience, te notificaremos sin demoras indebidas y te proporcionaremos información oportuna relacionada con el Incidente de Seguridad tal como se conozca o cuando nos lo solicites razonablemente.</p>
</div>

  </ng-container>
  <app-footer></app-footer>
</main>
