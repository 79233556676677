<main class="d-flex flex-column min-vh-100">

  <app-navbar />
  <ng-container>
    <div class="container py-5 flex-grow-1 animate__animated animate__fadeIn">
      <h1>Aviso legal</h1>

      <h4>1. DATOS IDENTIFICATIVOS</h4>
      <p>En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de julio, de
        Servicios
        de la Sociedad de la Información y del Comercio Electrónico, a continuación se reflejan los siguientes datos: el
        titular de dominio web es Andreu Florit Moll (en adelante Agrup Lab SL), con domicilio a estos efectos en
        C/ciutadella
        73 2-2 Mao 07701 Baleares, número de C.I.F.: B02877876. Correo electrónico de contacto: <strong><a
          href="mailto:info@mardefons.com">info&#64;remexperience.com</a></strong> del sitio web.</p>
      <h4>2. USUARIOS</h4>
      <p>El acceso y/o uso de este portal de REM Experience atribuye la condición de USUARIO, que acepta, desde dicho
        acceso
        y/o uso, las Condiciones Generales de Uso aquí reflejadas. Las citadas Condiciones serán de aplicación
        independientemente de las Condiciones Generales de Contratación que en su caso resulten de obligado
        cumplimiento.</p>
      <h4>3. USO DEL PORTAL</h4>
      <p>remexperience.com proporciona el acceso a multitud de informaciones, servicios, programas o datos (en adelante,
        “los
        contenidos”) en Internet pertenecientes a REM Experience o a sus licenciantes a los que el USUARIO pueda tener
        acceso.
        El USUARIO asume la responsabilidad del uso del portal.</p>
      <h4>4. PROTECCIÓN DE DATOS</h4>
      <p>REM Experience cumple con las directrices de la Ley Orgánica 15/1999 de 13 de diciembre de Protección de Datos
        de
        Carácter Personal, el Real Decreto 1720/2007 de 21 de diciembre por el que se aprueba el Reglamento de
        desarrollo de
        la Ley Orgánica y demás normativa vigente en cada momento, y vela por garantizar un correcto uso y tratamiento
        de los
        datos personales del usuario. Para ello, junto a cada formulario de recabo de datos de carácter personal, en los
        servicios que el usuario pueda solicitar a <strong><a href="mailto:info@mardefons.com">info&#64;remexperience
          .com</a></strong>, hará saber al usuario de la existencia y aceptación de las condiciones particulares del
        tratamiento de sus datos en cada caso, informándole de la responsabilidad del fichero creado, la dirección del
        responsable, la posibilidad de ejercer sus derechos de acceso, rectificación, cancelación u oposición, la
        finalidad
        del tratamiento y las comunicaciones de datos a terceros en su caso.</p>
      <p>Asimismo, REM Experience informa que da cumplimiento a la Ley 34/2002 de 11 de julio, de Servicios de la
        Sociedad de
        la Información y el Comercio Electrónico y le solicitará su consentimiento al tratamiento de su correo
        electrónico con
        fines comerciales en cada momento.</p>
      <h4>5. PROPIEDAD INTELECTUAL E INDUSTRIAL</h4>
      <p>REM Experience por sí o como cesionaria, es titular de todos los derechos de propiedad intelectual e industrial
        de su
        página web, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio,
        vídeo,
        software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales
        usados,
        programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), titularidad de REM Experience o
        bien de
        sus licenciantes.</p>
      <p>Todos los derechos reservados. En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley
        de
        Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación
        pública,
        incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con
        fines
        comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de REM Experience. El
        USUARIO se
        compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de REM Experience. Podrá
        visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su
        ordenador o
        en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado.
        El
        USUARIO deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema
        de
        seguridad que estuviera instalado en el las páginas de REM Experience.</p>
      <h4>6. EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</h4>
      <p>REM Experience no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que
        pudieran
        ocasionar, a título enunciativo: errores u omisiones en los contenidos, falta de disponibilidad del portal o la
        transmisión de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las
        medidas
        tecnológicas necesarias para evitarlo.</p>
      <h4>7. MODIFICACIONES</h4>
      <p>REM Experience se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en
        su
        portal, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma
        como
        la forma en la que éstos aparezcan presentados o localizados en su portal.</p>
      <h4>8. ENLACES</h4>
      <p>En el caso de que en remexperience.com se dispusiesen enlaces o hipervínculos hacía otros sitios de Internet,
        REM
        Experience no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso REM Experience
        asumirá
        responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la
        disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de
        cualquier
        material o información contenida en ninguno de dichos hipervínculos u otros sitios de Internet.</p>
      <p>Igualmente la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o
        participación
        con las entidades conectadas.</p>
      <h4>9. DERECHO DE EXCLUSIÓN</h4>
      <p>REM Experience se reserva el derecho a denegar o retirar el acceso a portal y/o los servicios ofrecidos sin
        necesidad
        de preaviso, a instancia propia o de un tercero, a aquellos usuarios que incumplan las presentes Condiciones
        Generales
        de Uso.</p>
      <h4>10.GENERALIDADES</h4>
      <p>REM Experience perseguirá el incumplimiento de las presentes condiciones así como cualquier utilización
        indebida de
        su portal ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.</p>
      <h4>11.MODIFICACIÓN DE LAS PRESENTES CONDICIONES Y DURACIÓN</h4>
      <p>REM Experience podrá modificar en cualquier momento las condiciones aquí determinadas, siendo debidamente
        publicadas
        como aquí aparecen.</p>
      <p>La vigencia de las citadas condiciones irá en función de su exposición y estarán vigentes hasta debidamente
        publicadas. que sean modificadas por otras.</p>
      <h4>12. LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h4>
      <p>La relación entre REM Experience y el USUARIO se regirá por la normativa española vigente y cualquier
        controversia se
        someterá a los Juzgados y tribunales de la ciudad de Mahón.</p>
    </div>
  </ng-container>
  <app-footer></app-footer>
</main>
