<div class="modal-header d-flex justify-content-center">
  <h5 class="modal-title text-primary" id="cookieconsentLabel1">Usamos cookies</h5>
</div>
<div class="modal-body p-4">
  Este sitio web utiliza cookies propias y de terceros para mejorar tu experiencia de navegación, analizar el uso de la web y personalizar el contenido.
</div>
<div class="modal-footer d-flex justify-content-center">
  <button type="button" class="btn btn-outline-primary" [routerLink]="['/cookies-policy']" (click)="activeModal.close('Close click')">Leer mas</button>
  <button type="button" class="btn btn-primary" (click)="accept()">Confirmar</button>
</div>

