import {Component, inject, OnDestroy} from '@angular/core';
import {NavbarComponent} from "../../core/core-components/navbar/navbar.component";
import {TranslocoDirective} from "@jsverse/transloco";
import {DropdownModule} from "primeng/dropdown";
import {Subscription} from "rxjs";
import {PlatformStoreService} from "../../shared/infrastructure/services/platform-store.service";
import {PlatformsApiInterface} from "../palataforms/palataforms.service";
import {FormsModule} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-platform-selector',
  standalone: true,
  imports: [
    NavbarComponent,
    TranslocoDirective,
    DropdownModule,
    FormsModule
  ],
  templateUrl: './platform-selector.component.html',
  styleUrl: './platform-selector.component.scss'
})
export class PlatformSelectorComponent implements OnDestroy{
  router = inject(Router);

  subscriptions: Subscription[] = []
  platforms!: PlatformsApiInterface[]
  selectedPlatform?: PlatformsApiInterface
  constructor(
    private platformStore: PlatformStoreService
  ) {
    this.subscriptions.push(
      this.platformStore.availablePlatforms.subscribe(platforms => {
          if (platforms) this.platforms = platforms
        }
      ),
      this.platformStore.platform.subscribe(platform => {
        this.selectedPlatform = platform
      })
    )
  }

  switchPlatform(){
    this.subscriptions.push(
      this.platformStore.platform.subscribe(platform => {
        if (platform) this.router.navigate(['/activities'])
      })
    )

    this.platformStore.setPlatform(this.platforms.find(platform => platform.id == this.selectedPlatform!.id)!)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
