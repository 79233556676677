import { Component } from '@angular/core';
import {NavbarComponent} from '../../../core/core-components/navbar/navbar.component';
import {FooterComponent} from '../../../core/core-components/footer/footer.component';

@Component({
  selector: 'app-legal-notice',
  standalone: true,
  imports: [
    NavbarComponent,
    FooterComponent,
  ],
  templateUrl: './legal-notice.component.html',
  styleUrl: './legal-notice.component.scss'
})
export class LegalNoticeComponent {

}
