import { Component } from '@angular/core';
import {FooterComponent} from '../../../core/core-components/footer/footer.component';
import {NavbarComponent} from '../../../core/core-components/navbar/navbar.component';

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [
    FooterComponent,
    NavbarComponent,
  ],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {

}
