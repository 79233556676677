<main class="d-flex flex-column min-vh-100">

  <app-navbar />
  <ng-container>
    <div class="container py-5 flex-grow-1 animate__animated animate__fadeIn">
      <h1>Política de Cookies</h1>

      <p>En [Nombre del Sitio Web] utilizamos cookies para mejorar tu experiencia en nuestro sitio, analizar el tráfico
        y personalizar el contenido que te ofrecemos. Al continuar navegando, aceptas el uso de cookies conforme a los
        términos establecidos en esta política.</p>
      <h3>1. ¿Qué son las cookies?</h3>
      <p>Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas un sitio web.
        Estas permiten que el sitio recuerde información sobre tu visita, lo que puede facilitar tu próxima visita y
        hacer que el sitio sea más útil para ti.</p>
      <h3>2. ¿Qué tipos de cookies utilizamos?</h3>
      <p>En [Nombre del Sitio Web] utilizamos los siguientes tipos de cookies:</p>
      <ul>
        <li>
          <p><strong>Cookies esenciales</strong>: Estas cookies son necesarias para el funcionamiento del sitio web y no
            pueden desactivarse en nuestros sistemas. Permiten funciones como acceder a áreas seguras del sitio.</p>
        </li>
        <li>
          <p><strong>Cookies de rendimiento</strong>: Estas cookies nos permiten medir y analizar cómo los usuarios
            interactúan con nuestro sitio, lo que nos ayuda a mejorar su funcionamiento y la experiencia del usuario.
          </p>
        </li>
        <li>
          <p><strong>Cookies de funcionalidad</strong>: Estas cookies permiten que el sitio recuerde las elecciones que
            has hecho (como tu idioma preferido o la región en la que te encuentras) para ofrecer una experiencia
            personalizada.</p>
        </li>
        <li>
          <p><strong>Cookies de publicidad</strong>: Estas cookies se utilizan para mostrar anuncios relevantes para ti.
            También nos ayudan a medir la efectividad de nuestras campañas publicitarias.</p>
        </li>
      </ul>
      <h3>3. ¿Cómo controlar las cookies?</h3>
      <p>Puedes configurar tu navegador para rechazar o aceptar cookies, así como para eliminar las cookies ya
        almacenadas en tu dispositivo. Sin embargo, si decides bloquear o eliminar las cookies, es posible que algunas
        funciones del sitio web no funcionen correctamente.</p>
      <h4>Gestión de cookies en navegadores comunes:</h4>
      <ul>
        <li><strong>Google Chrome</strong>: <a target="_new" rel="noopener">Enlace a instrucciones de Chrome</a></li>
        <li><strong>Mozilla Firefox</strong>: <a
          href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-" target="_new"
          rel="noopener">Enlace a instrucciones de Firefox</a></li>
        <li><strong>Safari</strong>: <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_new"
                                        rel="noopener">Enlace a instrucciones de Safari</a></li>
        <li><strong>Microsoft Edge</strong>: <a href="https://support.microsoft.com/es-es/microsoft-edge" target="_new"
                                                rel="noopener">Enlace a instrucciones de Edge</a></li>
      </ul>
      <h3>4. Cookies de terceros</h3>
      <p>En nuestro sitio, utilizamos servicios de terceros que también pueden almacenar cookies en tu dispositivo, como
        los proveedores de análisis (p.ej. Google Analytics) y las redes publicitarias. Estas cookies están reguladas
        por las políticas de privacidad de dichos terceros.</p>
      <h3>5. Cambios en la política de cookies</h3>
      <p>Nos reservamos el derecho de modificar esta política de cookies en cualquier momento, por lo que te
        recomendamos revisar esta página periódicamente. Los cambios entrarán en vigor en el momento de su publicación
        en el sitio web.</p>
      <h3>6. Contacto</h3>
      <p>Si tienes alguna pregunta sobre nuestra política de cookies, puedes ponerte en contacto con nosotros en [Correo
        de contacto].</p>
    </div>

  </ng-container>
  <app-footer></app-footer>
</main>
