import {Component, inject} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-cookies-modal',
  standalone: true,
  imports: [
    RouterLink,
  ],
  templateUrl: './cookies-modal.component.html',
  styleUrl: './cookies-modal.component.scss'
})
export class CookiesModalComponent {
activeModal = inject(NgbActiveModal);

accept(){
  localStorage.setItem('cookies-accepted', 'true')
  this.activeModal.close()

}

}
