import {inject} from "@angular/core";
import {PlatformStoreService} from "../../../../shared/infrastructure/services/platform-store.service";
import {
  PermissionsStoreService
} from "../../../permissions/infrastructure/services/permissions-store/permissions-store.service";
import {AuthStoreService} from "../services/auth-store/auth-store.service";
import {Router} from "@angular/router";

export function hasPlatform(){

    const platformStore = inject(PlatformStoreService)
    const router = inject(Router);
    const authStore = inject(AuthStoreService);
    const user = authStore.user();

    if (!platformStore.currentPlatform && user!.role === 'ADMIN_GLOBAL' ) {
      router.navigate(['/platform-selector'])
      return false;
    }
    return true;

}
